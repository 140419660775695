<template>
     <br><br><br><br>
     <div class="slick-single-layout" style="padding: 0 15px;">
          <div class="row row--15">
               <div v-if="isLoading">
                    <PlaceHolder></PlaceHolder>
               </div>
               <div class="col-xl-3 col-lg-4 col-sm-6 col-12 mb--30">
                    <div class="axil-product product-style-one">
                         <div class="thumbnail">
                              <Carousel>
                                   <Slide v-for="slide in images" :key="slide">
                                        <div class="carousel__item">
                                             <img style="width: 200px;height: 200px;margin-top: 40px" :src="slide.path"
                                                  alt="">
                                        </div>
                                   </Slide>
                                   <template #addons>
                                        <Navigation />
                                        <Pagination />
                                   </template>
                              </Carousel>
                              <div class="label-block label-right" v-if="inventory.mb_special_price"
                                   style="margin-top: 15%;">
                                   <div class="product-badget" style="background: #38d10b;">OFERTA</div>
                              </div>
                              <div class="label-block label-left">
                                   <div class="product-badget" v-if="product.simple_fields.refurbished === 1"
                                        style="background: #000;">
                                        REACONDICIONADO
                                   </div>
                              </div>
                         </div>
                         <div class="product-content">
                              <div class="inner">
                                   <div class="title">
                                        <h2 class="product-title" v-if="product.simple_fields">
                                             {{ product.simple_fields.name }}
                                        </h2>
                                   </div><br>
                                   <div class="product-price-variant">
                                        <div v-if="inventory.mb_special_price">
                                             <span class="price current-price">
                                                  {{ $filters.money(inventory.mb_special_price) }}
                                             </span>
                                             <span class="price old-price">
                                                  {{ $filters.money(inventory.mb_price) }}
                                             </span>
                                        </div>
                                        <div v-else>
                                             <span class="price current-price">
                                                  {{ $filters.money(inventory.mb_price) }}
                                             </span>
                                        </div>
                                        <div class="socialmedia">
                                             <a :href="'https://www.facebook.com/sharer/sharer.php?u=https://rc1.mainbitpreproduccion.shop/' + product.simple_fields.url_key"
                                                  class="button-facebook">
                                                  <i class="fab fa-facebook-square"></i>
                                             </a>
                                             <a :href="'https://www.instagram.com/?url=https://rc1.mainbitpreproduccion.shop/' + product.simple_fields.url_key"
                                                  class="button-instagram">
                                                  <i class="fab fa-instagram"></i>
                                             </a>
                                             <a :href="'https://twitter.com/intent/tweet?text==https://rc1.mainbitpreproduccion.shop/' + product.simple_fields.url_key"
                                                  class="button-twitter">
                                                  <i class="fab fa-twitter-square"></i>
                                             </a>
                                             <a :href="'https://api.whatsapp.com/send?text=https://rc1.mainbitpreproduccion.shop/' + product.simple_fields.url_key"
                                                  data-action="share/whatsapp/share" class="button-whatsapp">
                                                  <i class="fab fa-whatsapp-square"></i>
                                             </a>
                                        </div>
                                   </div><br>
                                   <div class="col-lg-6 mb--40">
                                        <div class="single-product-content">
                                             <div class="inner">
                                                  <div class="product-rating">
                                                       <div class="star-rating">
                                                            <i class="fas fa-star"></i>
                                                            <i class="fas fa-star"></i>
                                                            <i class="fas fa-star"></i>
                                                            <i class="fas fa-star"></i>
                                                            <i class="far fa-star"></i>
                                                       </div>
                                                       <div class="review-link">
                                                            <a href="#reviews">opiniones de clientes (<span>{{
                                                                 reviews.length }}</span>)</a>
                                                       </div>
                                                  </div>
                                                  <ul class="product-meta">
                                                       <li><i class="fal fa-check"></i>{{ product.inventory.qty }} en stock
                                                       </li>
                                                       <li><i class="fal fa-check"></i>Entrega gratuita disponible</li>
                                                       <li><i class="fal fa-check"></i>Transacción segura</li>
                                                       <!--li><i class="fal fa-check"></i>30% de descuento Use el código: MOTIVE30</li-->
                                                  </ul>
                                                  <p class="description" v-if="product.simple_fields">
                                                       <!-- {{ product.simple_fields[0].short_description }} -->
                                                  </p>

                                                  <!-- Start Product Action Wrapper  -->
                                                  <div class="product-action-wrapper d-flex-center">
                                                       <!-- Start Quentity Action  -->
                                                       <div class="pro-qty mr--20"><input type="text" v-model="quantity">
                                                       </div>
                                                       <!-- End Quentity Action  -->

                                                       <!-- Start Product Action  -->
                                                       <ul class="product-action d-flex-center mb--0">
                                                            <li class="add-to-cart">
                                                                 <AddToCartBtn :product="product" @click.prevent="add()">
                                                                      Añadir al carrito
                                                                 </AddToCartBtn>
                                                            </li>
                                                            <!--li class="add-to-cart" v-if="product.inventory && isLogged">
                                                                 <AddToCartBtn :product="product" @click.prevent="addCartTemp()">
                                                                      Añadir al carrito
                                                                 </AddToCartBtn>
                                                            </li-->
                                                            <li class="wishlist" v-if="wishlist">
                                                                 <a href="#modal" class="axil-btn wishlist-btn colorselect">
                                                                      <i class="far fa-heart" style="color:#fff"></i>
                                                                 </a>
                                                            </li>
                                                            <li class="wishlist" v-else>
                                                                 <a class="axil-btn wishlist-btn"
                                                                      @click.prevent="addWishlist()">
                                                                      <i class="far fa-heart"></i>
                                                                 </a>
                                                            </li>
                                                       </ul>
                                                       <!-- End Product Action  -->
                                                  </div>
                                                  <br><br>
                                                  <div class="row item-simple-product">
                                                       <div class="col-4">
                                                            <img style="width: 60%;"
                                                                 src="https://rc1.mainbitpreproduccion.shop/vendor/mainbit/footer/icons/Compras_seguras.png"
                                                                 alt="Pagos">
                                                            <span class="comment-review">Pagos y Seguridad</span>
                                                       </div>
                                                       <div class="col-4">
                                                            <img style="width: 60%;"
                                                                 src="https://rc1.mainbitpreproduccion.shop/vendor/mainbit/footer/icons/Entregas_garantizadas.png"
                                                                 alt="Envio">
                                                            <span class="comment-review">Devolución sin costo</span>
                                                       </div>
                                                       <div class="col-4">
                                                            <img style="width: 60%;"
                                                                 src="https://rc1.mainbitpreproduccion.shop/vendor/mainbit/footer/icons/Entregas_garantizadas.png"
                                                                 alt="Envio">
                                                            <span class="comment-review">Envíado por MainbitShop</span>
                                                       </div>
                                                  </div>
                                                  <!-- End Product Action Wrapper  -->
                                                  <div class="product-variations-wrapper">
                                                       <br>
                                                       <!-- Start Product Variation  -->
                                                       <!--table class="table text-left">
                                                            <tbody>
                                                                 <tr v-for="(attribute, atr) in product.attributes"
                                                                      :key="atr">
                                                                      <th scope="row text-left"><b>{{ attribute.name }}:</b>
                                                                      </th>
                                                                      <td>{{ attribute.text_value }}</td>
                                                                 </tr>
                                                            </tbody>
                                                       </table-->
                                                       <!-- End Product Variation  -->
                                                  </div>
                                                  <div class="product-desc-wrapper pt--80 pt_sm--60"
                                                       v-if="product.simple_fields">
                                                       <h5 class="title" style="color: #3b77f0;">Descripción:</h5>
                                                       <div class="single-desc mb--30" style="text-align: justify;">
                                                            <p>{{ product.simple_fields.description }}</p>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                        <div class="modal" id="modal">
                                             <a href="#" class="modal-bg"></a>
                                             <div class="modal-content">
                                                  <a href="#" class="modal-exit"><i class="fas fa-times"></i></a>
                                                  <h3 class="modal-title axil-breadcrumb-item">¿Eliminar de la lista de
                                                       deseos?</h3>
                                                  <div class="modal-text">
                                                       <div class="row">
                                                            <div class="col-6">
                                                                 <b-button variant="light">
                                                                      <a class="cancel-modal" href="#">Cancelar</a>
                                                                 </b-button>
                                                            </div>
                                                            <div class="col-6">
                                                                 <b-button variant="success"
                                                                      @click.prevent="removeItem()">Aceptar</b-button>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                        <div class="single-product-content">
                                             <details>
                                                  <summary class="title" style="color: #3b77f0;">Déjanos tu opinión</summary>
                                                  <vue3starRatings v-model="rating" />
                                                  <input v-model="titleReview" placeholder="Título" />
                                                  <textarea placeholder="Escribe tu opinión ..." rows="20" cols="40"
                                                       v-model="commentReview">
                                                  </textarea>
                                                  <b-button variant="info" @click.prevent="addReview()">Envíar</b-button>
                                             </details>
                                        </div><br>
                                        <div class="section-title-wrapper">
                                             <span class="title-highlighter highlighter-primary">
                                                  <i class="far fa-shopping-basket"></i>
                                                  Productos relacionados
                                             </span>
                                             <ProductReel :Reel="related"></ProductReel>
                                        </div>
                                        <div class="section-title-wrapper">
                                             <span class="title-highlighter highlighter-primary">
                                                  <i class="far fa-shopping-basket"></i>
                                                  Otros clientes también compraron
                                             </span>
                                             <ProductReel :Reel="up_sell"></ProductReel>
                                        </div>
                                        <div v-if="reviews.length > 0" id="reviews" class="single-product-content">
                                             <h5 class="title" style="color: #3b77f0;">Opiniones del producto</h5>
                                             <div class="inner single-review" v-for="(review, rw) in reviews" :key="rw">
                                                  <div>
                                                       <div class="row">
                                                            <div class="col-6 reviews-card"><br>
                                                                 {{ review.name }}
                                                            </div>
                                                            <div class="col-6"><br>
                                                                 <div class="product-rating">
                                                                      <div class="star-rating"
                                                                           v-for="(star, n) in review.rating" :key="n">
                                                                           <i class="fas fa-star"></i>
                                                                      </div>
                                                                      <div class="star-rating"
                                                                           v-for="(star, n) in 5 - review.rating" :key="n">
                                                                           <i class="far fa-star"></i>
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                       </div>
                                                       <h2 class="title-review">{{ review.title }}</h2>
                                                       <h2 class="comment-review">"{{ review.comment }}"</h2>
                                                       <div class="row">
                                                            <div class="col-6"></div>
                                                            <div class="col-6">
                                                                 {{ $filters.inDate(review.created_at) }}
                                                            </div>
                                                       </div><br>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</template>
<script>
import { Carousel, Navigation, Slide, Pagination } from 'vue3-carousel'
import ProductReel from "@/components/Reels/ProductReel.vue";
import vue3starRatings from "vue3-star-ratings";
import AddToCartBtn from './Partials/AddToCartBtn.vue'
import store from '@/store'
import { useToast } from "vue-toastification";
import PlaceHolder from '@/components/PlaceHolder/ProductDetail.vue'
import { mapState } from 'vuex'

export default {
     props: {
          product: {
               type: Object,
               required: true
          },
          images: {
               type: Object,
               required: true
          }
     },
     components: {
          AddToCartBtn,
          Carousel,
          Slide,
          Navigation,
          Pagination,
          PlaceHolder,
          vue3starRatings,
          ProductReel
     },
     data: () => ({
          quantity: 1,
          toast: useToast(),
          inventory: [],
          reviews: [],
          commentReview: null,
          titleReview: null,
          rating: 0,
          products: [],
          wishlist: false,
          totalQuantity: 0,
          related: [],
          up_sell: [],
     }),
     computed: {
          isLogged() {
               if (!store.state.user.token) {
                    return true;
               }
               return false;
          },
          ...mapState(['isLoading', 'productsTemp', 'productsCartTemp'])
     },
     mounted() {
          this.productInformation();
          if (store.state.user.token) {
               this.getWishlist();
          }
          this.relatedProducts();
     },   
     methods: {
          add() {
               if (store.state.user.token) {
                    store.dispatch("addProductToCart", {
                         quantity: this.quantity,
                         product_id: this.product.id,
                         is_buy_now: 0,
                         inventory_id: 0
                    })
                         .then(() => {
                              store.dispatch("getCart")
                              this.toast.success('Articulo añadido al carrito')

                         }).catch((err) => {
                              console.log(err);
                         });
               } else {
                    let exists = false
                    this.productsCartTemp.forEach((prod) => {
                         if (prod.productId == this.product.id) {
                              exists = true
                         }
                    });

                    if (this.quantity > this.product.inventory.qty && exists === false) {
                         this.productsTemp.push({ products: this.product, quantity: this.product.inventory.qty })
                         this.productsCartTemp.push({ productId: this.product.id, quantity: this.product.inventory.qty })
                         this.toast.success('Articulo añadido al carrito')
                    }
                    if (exists === false && this.quantity < this.product.inventory.qty) {
                         this.productsTemp.push({ products: this.product, quantity: parseInt(this.quantity) })
                         this.productsCartTemp.push({ productId: this.product.id, quantity: parseInt(this.quantity) })
                         this.toast.success('Articulo añadido al carrito')
                    }
                    if (exists === true) {
                         this.productsTemp.filter(product => {
                              if (product.products.id == this.product.id) {
                                   this.total = product.quantity += parseInt(this.quantity)
                              }
                         })
                         this.updateProductsTemp();
                         this.toast.success('Articulo añadido al carrito')
                    }
               }
          },
          updateProductsTemp() {
               const indiceElemento = this.productsTemp.findIndex(c => c.products.id == this.product.id)
               let newProductsTemp = [...this.productsTemp]
               newProductsTemp[indiceElemento] = { ...newProductsTemp[indiceElemento], quantity: this.total }
               this.productsCartTemp.splice(0, this.productsCartTemp.length)
               this.productsTemp.forEach((prod) => {
                    if(this.total > this.product.inventory.qty){
                         this.productsCartTemp.push({ productId: prod.products['id'], quantity: this.product.inventory.qty })
                    }else{
                         this.productsCartTemp.push({ productId: prod.products['id'], quantity: prod['quantity'] })
                    }
               });
               
          },
          addWishlist() {
               if (store.state.user.token) {
                    this.isLoading = true
                    store.dispatch("addProductToWishlist", {
                         productId: this.product.id
                    }).then(() => {
                         this.toast.success('Artículo añadido a favoritos')
                    }).catch((err) => {
                         console.log(err);
                    }).finally(() => {
                         this.getWishlist()
                    });
               } else {
                    this.toast.error('Inicia sesión para poder realizar esta acción')
               }
          },
          addReview() {
               if (store.state.user.token) {
                    store.dispatch("createProductReview", {
                         "title": this.titleReview,
                         "comment": this.commentReview,
                         "rating": this.rating,
                         "product_id": this.product.id
                    }).then(() => {
                         this.toast.success('Reseña agregada exitosamente.')
                    }).catch((err) => {
                         console.log(err);
                    }).finally(() => {
                         this.commentReview = null
                         this.titleReview = null
                         this.rating = 0
                    });
               } else {
                    this.toast.error('Inicia sesión para poder realizar esta acción')
               }
          },
          productInformation() {
               this.inventory = this.product.inventory;
               this.reviews = this.product.productReviews.filter(review => review.status === "approved")
          },
          removeItem() {
               this.isLoading = true
               store.dispatch('removeProductFromWishlist', {
                    productId: this.product.id,
               }).then((res) => {
                    this.toast.success(res.data.messageForUser)
               }).catch((err) => {
                    console.log(err);
               }).finally(() => {
                    store.dispatch("getWishlist")
                    this.wishlist.pop()
                    window.location.href = '#';
               });
          },
          getWishlist() {
               store.dispatch('getWishlist')
               .then((res) => {
                    res.data.wishlist.filter(list => {
                        if(list.product_id == this.product.simple_fields.product_id){
                              this.wishlist = true;
                        }
                    })
               })
               .catch(error => {
                    console.error('Error fetching getWishlist:', error);
               });
          },
          relatedProducts(){
               console.log("id del producto", this.product.id)
               store.dispatch('relatedProducts', this.product.id)
               .then((res) => {
                   console.log("productos relacionados", res.data.payload.related)
                   this.related = res.data.payload.related.related.slice(this.related.length - 10);
                   this.up_sell = res.data.payload.related.up_sells.slice(this.up_sell.length - 10);
               })
               .catch(error => {
                    console.error('Error fetching getWishlist:', error);
               });
          }
     }
}
</script>

<style>
.table> :not(caption)>*>* {
     padding: 0.5rem 0.5rem;
     color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
     background-color: #fbfcfd;
     border-bottom-width: var(--bs-border-width);
     box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}

.table>tbody {
     vertical-align: inherit;
     text-align: justify;
}

.carousel__item {
     padding: 0 30px;
}

.carousel__pagination {
     display: flex;
     justify-content: center;
     list-style: none;
     line-height: 0;
     margin: 1px 0 0;
}

.carousel__track {
     display: flex;
     padding: 0 !important;
     position: relative;
     margin-bottom: 0px;
     margin-top: 0px;
}

textarea {
     margin-top: 10px;
     width: 500px;
     height: 80px;
     -moz-border-bottom-colors: none;
     -moz-border-left-colors: none;
     -moz-border-right-colors: none;
     -moz-border-top-colors: none;
     background: none repeat scroll 0 0 rgba(0, 0, 0, 0.07);
     border-color: -moz-use-text-color #FFFFFF #FFFFFF -moz-use-text-color;
     border-image: none;
     border-radius: 6px 6px 6px 6px;
     border-style: none solid solid none;
     border-width: medium 1px 1px medium;
     box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12) inset;
     color: #555555;
     font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
     font-size: 1.5em;
     line-height: 1.4em;
     padding: 5px 8px;
     transition: background-color 0.2s ease 0s;
}

textarea:focus {
     background: none repeat scroll 0 0 #FFFFFF;
     outline-width: 0;
}

input {
     margin-top: 10px;
     width: 500px;
     height: 30px;
     -moz-border-bottom-colors: none;
     -moz-border-left-colors: none;
     -moz-border-right-colors: none;
     -moz-border-top-colors: none;
     background: none repeat scroll 0 0 rgba(0, 0, 0, 0.07);
     border-color: -moz-use-text-color #FFFFFF #FFFFFF -moz-use-text-color;
     border-image: none;
     border-radius: 6px 6px 6px 6px;
     border-style: none solid solid none;
     border-width: medium 1px 1px medium;
     box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12) inset;
     color: #555555;
     font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
     font-size: 1.5em;
     line-height: 1.4em;
     padding: 5px 8px;
     transition: background-color 0.2s ease 0s;
}

input:focus {
     background: none repeat scroll 0 0 #FFFFFF;
     outline-width: 0;
}

.colorselect {
     background: #ff0000;
     color: #fff;
}
</style>