import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick.css';
import 'jquery-ui-dist/jquery-ui.min.css';
import 'sal.js/dist/sal.css';
import 'magnific-popup/dist/magnific-popup.css';

import '@/assets/css/style.css';
import '@/assets/css/vendor/bootstrap.min.css';
import '@/assets/css/vendor/font-awesome.css';
import '@/assets/css/vendor/flaticon/flaticon.css';
import '@/assets/css/vendor/slick.css';
import '@/assets/css/vendor/slick-theme.css';
import '@/assets/css/vendor/jquery-ui.min.css';
import '@/assets/css/vendor/sal.css';
import '@/assets/css/vendor/magnific-popup.css';
import '@/assets/css/vendor/base.css';

import 'jquery/dist/jquery.min.js';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/js/bootstrap';
import 'slick-carousel/slick/slick.min.js';
import 'jquery-ui-dist/jquery-ui.min.js';
import 'jquery-countdown/dist/jquery.countdown.min.js';
import 'sal.js/dist/sal.js';
import 'magnific-popup/dist/jquery.magnific-popup.min.js';
import 'imagesloaded/imagesloaded.pkgd.min.js';
import 'cookie/index.js';

// Default theme
import '@splidejs/splide/css';


// or other themes
import '@splidejs/splide/css/skyblue';
import '@splidejs/splide/css/sea-green';


// or only core styles
import '@splidejs/splide/css/core';

// Explicitly assign jQuery to the global window object to make it available to other scripts
window.jQuery = window.$ = require('jquery');

//Bootstrap
import BootstrapVueNext from 'bootstrap-vue-next'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'

// Continue with the rest of the scripts
import 'isotope-layout/dist/isotope.pkgd.min.js';
// import 'waypoints/lib/jquery.waypoints.min.js'; -> triggers error due to imcompatibilities in jquery versions. Check if it's really needed

//Toast notifications

import Toast, { POSITION, TYPE } from "vue-toastification";
import "vue-toastification/dist/index.css";

const options = {
     toastDefaults: {
          [TYPE.SUCCESS]: {
               timeout: 3000,
               hideProgressBar: false,
          },
          [TYPE.ERROR]: {
               timeout: 2000,
               hideProgressBar: false,
          },
     },
     transition: "Vue-Toastification__fade",
     maxToasts: 5,
     newestOnTop: true,
     position: POSITION.TOP_CENTER,
     closeButton: false,
};

//end toast notififactions
//moment js
import moment from 'moment'
require('moment/locale/es')
//end momentjs

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueSplide from '@splidejs/vue-splide';
import vue3StarRatings from "vue3-star-ratings";

const app = createApp(App)

app.config.globalProperties.$filters = {
     /**
      * 
      * @param {string|float|integer|double} value 
      * Usage
      * {{ $filters.money(Value) }}
      * Where value is a amount to parse
      * function parse auto to float
      */
     money(value) {
          let val = (parseFloat(value)/1).toFixed(2).replace(',', '.')
          return '$'+ val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
     },
     /**
      * 
      * @param {string} data String to short
      * @param {integer} num characters to show
      * @returns short string
      */
     truncate(data, num){
          const string = data.split("").slice(0, num).join("");
          return string+'...';
     },
     /**
      * 
      * @param {Date} date 
      * @returns 
      */
     inDate(date){
          if(moment(date)){
               return moment(date).format('DD MMMM YYYY, h:mm a');
          }
          return date;
     }
}

// logto integration
import { createLogto } from "@logto/vue";

const logtoConfig = {
     endpoint: "https://auth.mainbitpreproduccion.shop/",
     appId: "4ohpivehs21n5nvawj9h7",
     scopes: ['openid', 'profile', 'email'],
     resources: ['https://api.mainbitpreproduccion.shop'],
};
import VueScrollTo from 'vue-scrollto';
app.config.globalProperties.$logtoEndpoint = logtoConfig.endpoint;
app.use(store)
     .use(router)
     .use(BootstrapVueNext)
     .use(Toast, options)
     .use( VueSplide )
     .use(vue3StarRatings)
     .use(moment)
     .use(createLogto, logtoConfig)
     .use(VueScrollTo)
     .mount('#app');
