<template>
    <!-- Start Mainmenu Area  -->
    <div class="content">
        <header>
            <nav class="menu--right" role="navigation">
                <div class="menuToggle">
                <input type="checkbox" v-model="isMenuOpen" />
                <span></span>
                <span></span>
                <span></span>
                <ul class="text-left text-black ul-color-text menuItem">
                    <div class="row" v-if="!isLogged">
                        <div class="col-4 text-right" v-if="!userImg">
                            <img  class="img-circle" src="https://rc1.mainbitpreproduccion.shop/vendor/mainbit/footer/icons/logo_footer.png" alt="Profile">
                        </div>
                        <div class="col-8 text-right" style="display: flex;align-items: center;font-size: 18px;">
                            Hola, {{ name }} !
                        </div>
                    </div>
                    <div v-else class="back-sig-in">
                        <li v-if="isLogged" class="text-li-sign-in">
                            <router-link :to="{ name: 'Sign In' }" class="text-white" @click="closeMenu">
                                <label for="" style="margin-top: 10px;">Identificarse</label>
                            </router-link>
                        </li>
                    </div>
                    <div v-if="Categories.length === 0" class="overlay">
                        <div class="spinner"></div>
                    </div>
                    <li v-if="!isLogged" style="margin-top: 8%;">
                        <router-link :to="{ name: 'Profile' }" @click="closeMenu">
                            <div class="col-lg-6 div-li"> 
                                <div class="row margin-div">
                                    <div class="col-2 margin-icon">
                                        <i class="far fa-user li-icon"></i>
                                    </div>
                                    <div class="col-10 text-left text-li-menu">
                                        <b><h3>Información personal</h3></b>
                                    </div>
                                </div>
                            </div>
                        </router-link>
                        <hr>
                    </li>
                    <li v-if="!isLogged">
                        <router-link :to="{ name: 'Orders' }" class="router-link" @click="closeMenu">
                            <div class="col-lg-6 div-li"> 
                                <div class="row margin-div">
                                    <div class="col-2 margin-icon">
                                        <i class="far fa-archive li-icon"></i>
                                    </div>
                                    <div class="col-10 text-left text-li-menu">
                                        <b><h3>Pedidos</h3></b>
                                    </div>
                                </div>
                            </div>
                        </router-link>
                        <hr>
                    </li>
                    <li v-if="!isLogged">
                        <router-link :to="{ name: 'WishList' }" class="router-link" @click="closeMenu">
                            <div class="col-lg-6 div-li"> 
                                <div class="row margin-div">
                                    <div class="col-2 margin-icon">
                                        <i class="far fa-heart li-icon"></i>
                                    </div>
                                    <div class="col-10 text-left text-li-menu">
                                        <b><h3>Wishlist</h3></b>
                                    </div>
                                </div>
                            </div>
                        </router-link>
                        <hr>
                    </li>
                    <li v-if="!isLogged">
                        <router-link :to="{ name: 'My Reviews' }" class="router-link" @click="closeMenu">
                            <div class="col-lg-6 div-li"> 
                                <div class="row margin-div">
                                    <div class="col-2 margin-icon">
                                        <i class="far fa-comment li-icon"></i>
                                    </div>
                                    <div class="col-10 text-left text-li-menu">
                                        <b><h3>Reseñas</h3></b>
                                    </div>
                                </div>
                            </div>
                        </router-link>
                        <hr>
                    </li>
                    <li v-if="!isLogged">
                        <router-link :to="{ name: 'Addresses' }" class="router-link" @click="closeMenu">
                            <div class="col-lg-6 div-li"> 
                                <div class="row margin-div">
                                    <div class="col-2 margin-icon">
                                        <i class="far fa-map-marked-alt li-icon"></i>
                                    </div>
                                    <div class="col-10 text-left text-li-menu">
                                        <b><h3>Direcciones</h3></b>
                                    </div>
                                </div>
                            </div>
                        </router-link>
                        <hr>
                    </li>
                    <li style="margin-top: 8%;">
                        <router-link :to="{ name: 'Addresses' }" class="router-link" @click="closeMenu">
                            <div class="col-lg-6 div-li"> 
                                <div class="row margin-div">
                                    <div class="col-2 margin-icon">
                                        <i class="far fa-percent li-icon"></i>
                                    </div>
                                    <div class="col-10 text-left text-li-menu">
                                        <b><h3>Ofertas</h3></b>
                                    </div>
                                </div>
                            </div>
                        </router-link>
                        <hr>
                    </li>
                    <li>
                        <div class="col-lg-6 div-li"> 
                            <div class="row margin-div" style="margin-top: -13% !important;">
                                <div class="col-2 margin-icon">
                                    <i class="far fa-bars li-icon" style="margin-top: 20px;"></i>
                                </div>
                                <div class="col-10 text-left text-li-menu">
                                    <div class="collapse-container">
                                        <input type="checkbox" id="collapse-menu" class="collapse-toggle">
                                        <label for="collapse-menu" class="collapse-header">
                                            <b><h3 style="text-align: left;color: #518ce5;margin-left: -30px;margin-top: 10px">Categorias</h3></b>
                                        </label>
                                        <div class="collapse-content">
                                            <div class="scrollable-div">
                                            <div v-for="(category, index) in Categories" :key="index">
                                                <input type="checkbox" :id="'collapse2' + index" class="collapse-toggle">
                                                <label style="margin-left: -30px;" :for="'collapse2' + index" class="collapse-header">{{ category.translations.name }}</label>
                                                <div class="collapse-content-2">
                                                    <div v-for="(subCategory, ix) in SubCategories" :key="ix" class="menu-item">
                                                        <router-link
                                                            @click="closeMenu"
                                                            :to="{ name: 'Category', params: { 'slug': subCategory.translations.slug } }"
                                                            v-show="category.id === subCategory.parent_id">
                                                            {{ subCategory.translations.name }}
                                                        </router-link>
                                                    </div>
                                                </div>
                                            </div></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                    </li>
                    <li v-if="!isLogged">
                        <router-link :to="{ name: 'Addresses' }" class="router-link" @click="closeMenu">
                            <div class="col-lg-6 div-li"> 
                                <div class="row margin-div">
                                    <div class="col-2 margin-icon">
                                        <i class="far fa-bell li-icon"></i>
                                    </div>
                                    <div class="col-10 text-left text-li-menu">
                                        <b><h3>Notificaciones</h3></b>
                                    </div>
                                </div>
                            </div>
                        </router-link>
                        <hr>
                    </li>
                    <li>
                        <a @click.prevent="openSearch" @click="closeMenu">
                            <div class="col-lg-6 div-li"> 
                                <div class="row margin-div">
                                    <div class="col-2 margin-icon">
                                        <i class="far fa-search li-icon"></i>
                                    </div>
                                    <div class="col-10 text-left text-li-menu">
                                        <b><h3>Buscar</h3></b>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <hr>
                    </li>
                    <li>
                        <router-link :to="{ name: 'Index' }" class="router-link" @click="closeMenu">
                            <div class="col-lg-6 div-li"> 
                                <div class="row margin-div">
                                    <div class="col-2 margin-icon">
                                        <i class="far fa-home li-icon"></i>
                                    </div>
                                    <div class="col-10 text-left text-li-menu">
                                        <b><h3>Inicio</h3></b>
                                    </div>
                                </div>
                            </div>
                        </router-link>
                        <hr>
                    </li>
                    <li v-if="!isLogged">
                        <router-link :to="{ name: 'Tickets' }" class="router-link" @click="closeMenu">
                            <div class="col-lg-6 div-li"> 
                                <div class="row margin-div">
                                    <div class="col-2 margin-icon">
                                        <i class="far fa-question li-icon"></i>
                                    </div>
                                    <div class="col-10 text-left text-li-menu">
                                        <b><h3>Tickets</h3></b>
                                    </div>
                                </div>
                            </div>
                        </router-link>
                        <hr>
                    </li>
                </ul>
                </div>
            </nav>
        </header>
    </div>
    <nav class="navbar-e">
        <router-link :to="{ name: 'Index' }">
            <div class="row">
                <i class="far fa-home text-white"></i><br>
                <label for="" class="text-white">Home</label>
            </div>
        </router-link>
        <router-link :to="{ name: 'WishList' }">
            <div class="row">
                <i class="far fa-heart text-white"></i><br>
                <label for="" class="text-white">Favoritos</label>
            </div>
        </router-link>
        <a @click.prevent="openSearch">
            <div class="row">
                <i class="far fa-search text-white"></i><br>
                <label for="" class="text-white">Buscar</label>
            </div>
        </a>
        <a href="#">
            <div class="row">
                <i class="far fa-comment text-white"></i><br>
                <label for="" class="text-white">Mensajes</label>
            </div>
        </a>
        <router-link :to="{ name: 'Profile' }" class="router-link">
            <div class="row">
                <i class="far fa-user text-white"></i><br>
                <label for="" class="text-white">Perfil</label>
            </div>
        </router-link>
    </nav>
    <CartDropdown :openDialogCart="showDialogCart" @changeDialogCart="openSideCartBar" :cart="cart" />
    <SearchDropdown :openSearch="showSearch" @changeSearch="openSearch" />
    <ProfileDropdown :openProfile="showProfile" @changeProfile="openProfile" />
    <!-- End Mainmenu Area -->
</template>

<script>
import '@/assets/css/style.css';
import CartDropdown from "./CartDropdown.vue"
import SearchDropdown from "./SearchDropdown.vue"
import ProfileDropdown from "./SearchDropdown.vue"
import store from '@/store/index.js'
//import router from "@/router";
import { mapState } from 'vuex'
import { useLogto } from '@logto/vue';
import { useAuth } from '@/composables/useAuth';

export default {
    name: 'NavBar',
    props: {
        Categories: {
            required: true,
            type: Array
        },
        user: {
            required: false
        },
        cart: {
            required: false
        },
        SubCategories: {
            required: true,
            type: Array
        },
        Error: {
            required: true
        },
    },
    components: {
        CartDropdown,
        SearchDropdown,
        ProfileDropdown
        // Slide,
    },
    data: () => ({
        currentUser: useAuth(),
        showNavMenu: false,
        showMask: false,
        showDialogCart: false,
        showSearch: false,
        showProfile: false,
        showDropDown: false,
        welcome: " ",
        total: 0,
        isValidToken: false,
        token: null, // Estado del token
        tokenExpired: false,
        isMenuOpen: false, 
    }),
    computed: {
        isLogged() {
            if (!store.state.user.token) {
                return true;
            }
            return false;
        },
        ...mapState(['isLoading', 'productsCartTemp', 'productsTemp','name'])
    },
    mounted() {
        this.token = store.state.user.token; 
        this.tokenExpired = store.state.user.tokenExpired
        if (store.state.user.token) {
            this.getProfile();
        }
        console.log("hshshshhshshs",this.Categories.length)
        if(this.Categories.length > 0){
            this.isLoading = false
        }else{
            this.isLoading = true
        }
    },
    created() {
        this.logto = useLogto();
    },
    methods: {
        toggleMenu() {
            this.isMenuOpen = !this.isMenuOpen; // Alterna el estado del menú
            console.log("1111", this.isMenuOpen)
        },
        closeMenu() {
            this.isMenuOpen = false; // Cierra el menú
            console.log("2222", this.isMenuOpen)
        },
        checkLogin(){
            if(this.tokenExpired){
                const accessToken = this.logto.getAccessToken('https://api.mainbitpreproduccion.shop');
                if (!accessToken.success) {
                    return this.handleLogOut();
                }
                sessionStorage.setItem('TOKEN', accessToken);
                store.state.user.token = accessToken;
                store.state.email = this.userDataFromLogTo.email;
                store.dispatch('userProfile', {
                    accessToken: accessToken, 
                    email: this.userDataFromLogTo.email
                })
            }
        },  
        handleLogin() {
            const exampleToken = store.state.user.token; 
            this.token = exampleToken;
        },
        handleLogOut() {
            this.logto.clearAccessToken();
            this.logto.clearAllTokens();
            store.state.tokenExpired = true;
            sessionStorage.removeItem('TOKEN');
            sessionStorage.removeItem('REFRESH_TOKEN');
            //this.logto.signOut('http://localhost:8080/');
            this.logto.signOut('https://mobile.mainbitpreproduccion.shop/');
        },
        async handleRegister() {
            const redirectUri = 'https://mobile.mainbitpreproduccion.shop/callback';
            try {
                const result = await this.logto.signIn(redirectUri, { interactionMode: 'signUp' });
                console.log('Sign in result:', result);
                window.location.href = 'https://auth.mainbitpreproduccion.shop/register';
            } catch (error) {
                console.error('Error during sign up:', error);
                // Puedes agregar más lógica para manejar el error aquí
            }
        },
        totalTemp() {
            if (this.isLogged && this.productsCartTemp.length > 0) {
                this.total = this.productsCartTemp.reduce((a, b) => a + parseInt(b.quantity), 0);
                return this.total
            }
        },
        openNavMobileMenu() {
            this.showNavMenu = !this.showNavMenu
            this.showMask = !this.showMask
        },
        addClassForMainMenu() {
            let itemRef = this.$refs['itemMenu-1']
            let subRef = this.$refs['subMenu-1']
            // this.$ref['subMenu-1'].style
            if (subRef.style.display == "") {
                itemRef.classList.value = 'menu-item-has-children open'
                subRef.style.display = "block"
            } else {
                subRef.style.display = ""
                itemRef.classList.value = 'menu-item-has-children'
            }
        },
        openSideCartBar() {
            this.showDialogCart = !this.showDialogCart
            this.showMask = !this.showMask
        },
        openSearch() {
            this.showSearch = !this.showSearch
            this.showMask = !this.showMask
        },
        openProfile() {
            this.showProfile = !this.showProfile
        },
        getProfile(){
            store.dispatch('getUser');
        },
        redirectToRegister() {
            this.logto.clearAccessToken();
            this.logto.clearAllTokens();
            try {
                // URL de registro de Logto
                const registerUrl = this.$logtoEndpoint + 'register';
                
                // Redirige al usuario a la URL de registro
                window.location.href = registerUrl;
            } catch (error) {
                // Manejo de errores
                this.errorMessage = error.message || 'An error occurred during redirection.';
                console.error('Error during redirection:', error.message || error);
            }
        }
            // getCategories() {
        //     store.dispatch("getCategories")
        //     .then(response => {
        //         console.log('Categories:', response.data);
        //         this.categories = response.data;
        //     })
        //     .catch(error => {
        //         console.error('Error fetching Categories:', error);
        //     })
        //     .finally(() => {
        //         this.loading = false;
        //     });
        // },
    }, // methods
};
</script>
<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}

.bm-overlay {
    background: transparent;
}

.bm-burger-button {
    position: absolute;
    width: 22px;
    height: 16px;
    left: 90.5%;
    top: 36px;
    cursor: pointer;
}

.bm-burger-bars {
    background-color: #303037;
}

.bm-menu {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    background-color: #eaeaeb;
    overflow-x: hidden;
    padding-top: 60px;
    -webkit-transition: .5s;
    transition: .5s;
}

.bm-item-list>*>span {
    margin-left: 10px;
    font-weight: 700;
    color: #000;
}

.closeMask {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, .6);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 6;
    transition: .3s;
}

.header-main-nav.open .mainmenu-nav .mainmenu li:nth-child(1n) {
    transition-delay: .3s;
}

.header-main-nav.open .mainmenu-nav .mainmenu li {
    transform: translateY(0);
    opacity: 1;
}

@media only screen and (max-width: 991px) {
    .header-main-nav .mainmenu-nav .mainmenu>li.menu-item-has-children .axil-submenu {
        display: none;
        position: static;
        transform: scaleY(1);
        visibility: visible;
        opacity: 1;
        min-width: auto;
        box-shadow: none;
        padding: 0;
        transition: initial;
    }
}

.btn-primary {
    --bs-btn-color: #000;
    --bs-btn-bg: transparent;
    --bs-btn-border-color: none;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: transparent;
    --bs-btn-hover-border-color: none;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: transparent;
    --bs-btn-active-border-color: #0a53be;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #0d6efd;
    --bs-btn-disabled-border-color: none;
}

.modal-dialog {
    position: relative;
    width: auto;
    pointer-events: none;
    margin: 3%;
}

.modal-footer {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: right;
    padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
    background-color: var(--bs-modal-footer-bg);
    border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
    border-bottom-right-radius: var(--bs-modal-inner-border-radius);
    border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}

.position-loader {
    position: absolute !important;
    top: 50%;
    left: 50%;
}

.spinner-grow {
    --bs-spinner-width: 2rem;
    --bs-spinner-height: 2rem;
    --bs-spinner-vertical-align: -0.125em;
    --bs-spinner-animation-speed: 0.75s;
    --bs-spinner-animation-name: spinner-grow;
    background-color: red !important;
    opacity: 0;
}
.text-bienvenida {
    font-size: 15px;
    color: #5588bc;
    text-transform: capitalize;
}

.dropdown {
    max-width: 13em;
    position: relative;
    width: 100%;
}

.dropdown-btn {
    font-size: 18px;
    width: 100%;
    border: none;
    color: #848691;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.7em 0.1em;
    border-radius: 0.5em;
    cursor: pointer;
    background-color: transparent;
}

.arrow {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #fff;
    transition: transform ease-in-out 0.3s;
}

.dropdown-content {
    list-style: none;
    position: absolute;
    width: 155px;
    visibility: hidden;
    overflow: hidden;
    left: -30px;
}

.dropdown-content li {
    position: relative;
    transition: 0.5s;
    transition-delay: calc(60ms * var(--delay));
}

.dropdown:focus-within .dropdown-content li {
    left: 0;
}

.dropdown:focus-within .dropdown-content {
    visibility: visible;
}

.dropdown:focus-within .dropdown-btn>.arrow {
    transform: rotate(180deg);
}

.dropdown-content li:hover {
    background: #fff;
}

.dropdown-content li a {
    display: block;
    padding: 0em 0em;
    color: #000;
    margin: 0.1em 0;
    text-decoration: none;
    width: 120px;
    font-size: 12px;
}

.sign-in {
    color: #e72043 !important;
    text-decoration: underline !important;
    font-size: 14px !important;
    font-weight: 600 !important;
}

.sign-up {
    color: #1a76af !important;
    font-size: 14px !important;
    font-weight: 400 !important;
}

.dropdown {
    display: inline-block;
    position: relative;
}



.dd-button {
    display: inline-block;
    padding: 10px 0px 10px 0px;
    cursor: pointer;
    white-space: nowrap;
    margin-right: 10px;
}

.dd-button:after {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid black;
}

.dd-button:hover {
    background-color: transparent;
}


.dd-input {
    display: none;
}

.dd-menu {
    position: absolute;
    top: 100%;
    /* border: 1px solid #ccc; */
    border-radius: 4px;
    padding: 0;
    /* margin: 2px 0 0 0; */
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    list-style-type: none;
    margin-left: -125px;
}

.dd-input+.dd-menu {
    display: none;
}

.dd-input:checked+.dd-menu {
    display: block;
}

.dd-menu li {
    padding: 10px 20px;
    cursor: pointer;
    white-space: nowrap;
}

.dd-menu li:hover {
    background-color: #f6f6f6;
}

.dd-menu li a {
    display: block;
    margin: -10px -20px;
    padding: 10px 20px;
}

.dd-menu li.divider {
    padding: 0;
    border-bottom: 1px solid #cccccc;
}

.fixed-nav {
    position: fixed;
    z-index: 10;
    background: #fff;
    width: 100%;
    padding: 0px 5px !important;
}

/*.dropdown {
    max-width: 13em;
    position: relative;
    width: 100%;
}

.dropdown-btn {
    font-size: 18px;
    width: 100%;
    border: none;
    color: #000;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.arrow {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #fff;
    transition: transform ease-in-out 0.3s;
}

.dropdown-content {
    list-style: none;
    position: absolute;
    width: 100%;
    visibility: hidden;
    transform: translateY(-1em);
    transition: transform ease 0.3s;
    min-width: 170px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    margin-left: -86px;
}

input[type="checkbox"]:checked~.dropdown-content {
    visibility: visible;
    transform: translateY(0);
}

.dropdown-content li {
    border-radius: 0.5em;
}

input[type="checkbox"]:checked+label>.arrow {
    transform: rotate(180deg);
}

input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    display: none;
}

input[type=checkbox].hidden {
    opacity: 0;
}

.hidden {
    display: none;
}

.dropdown-content li a {
    display: block;
    padding: 0.1em 0.5em;
    color: #000;
    margin: 0.1em 0;
    text-decoration: none;
    margin-left: -20px;
}*/</style>

